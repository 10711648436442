import store from "@/state/store";

export const logoHelper = {
    logo,
    logoLight,
    logoDark
};

const logoDarkFile = require('@/assets/images/logo-dark.png');
const logoLightFile = require('@/assets/images/logo-light.png');
const logoDarkBetaFile = require('@/assets/images/beta/logo-dark.png');
const logoLightBetaFile = require('@/assets/images/beta/logo-light.png');
const envName = "demo" // In vue, we can only create development, production and test environments - https://cli.vuejs.org/guide/mode-and-env.html#environment-variables

function logo() {
    if (store.getters["theme/isThemeDark"]) {
        return this.logoLight()
    }

    return this.logoDark()
}

function logoLight() {
    return process.env.VUE_APP_ECAT_ENV === envName ? logoLightBetaFile : logoLightFile
}

function logoDark() {
    return process.env.VUE_APP_ECAT_ENV === envName ? logoDarkBetaFile : logoDarkFile
}