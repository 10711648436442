<script>
import {logoHelper} from "@/helpers/logo-helper";
import axios from "axios";
import {routerHelper} from "../../../helpers/router-helper";

export default {
  computed: {
    logoHelper() {
      return logoHelper
    }
  },

  data() {
    return {
      intervalId: 0
    }
  },

  methods: {
    async heartBeat() {
      try {
        await axios.get(`/heart-beat`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        });

        clearInterval(this.intervalId);
        routerHelper.push("/")
      } catch (error) {
        // ignored
      }
    },

    enableInterval() {
      this.intervalId = setInterval(async () => {
        await this.heartBeat()
      }, 2000);
    }
  },

  created() {
    document.body.classList.remove("auth-body-bg");
    this.enableInterval()
  }

};
</script>

<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <a href="/" class="text-dark">
        <i class="mdi mdi-home-variant h2"></i>
      </a>
    </div>
    <div class="my-5 pt-sm-5">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="text-center">
              <div class="mb-5">
                <a href="/">
                  <img :src="logoHelper.logo()"  alt="logo" height="64" />
                </a>
              </div>

              <div class="row justify-content-center">
                <div class="col-sm-4">
                  <div class="maintenance-img">
                    <img
                      src="@/assets/images/maintenance-bg.png"
                      alt
                      class="img-fluid mx-auto d-block"
                    />
                  </div>
                </div>
              </div>
              <h3 class="mt-5">Site is Under Maintenance</h3>
              <p>Please check back in sometime.</p>

              <div class="row">
                <div class="col-md-4">
                  <div class="mt-4 maintenance-box">
                    <div class="p-3">
                      <div class="avatar-sm mx-auto">
                        <span class="avatar-title bg-soft-primary rounded-circle">
                          <i class="mdi mdi-access-point-network font-size-24 text-primary"></i>
                        </span>
                      </div>

                      <h5 class="font-size-15 text-uppercase mt-4">Why is the Site Down?</h5>
                      <p class="text-muted mb-0">
                        It could be a scheduled maintenance window during which we perform necessary updates and maintenance on our servers. We strive to minimize the impact on users, but sometimes technical breaks are necessary.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="mt-4 maintenance-box">
                    <div class="p-3">
                      <div class="avatar-sm mx-auto">
                        <span class="avatar-title bg-soft-primary rounded-circle">
                          <i class="mdi mdi-clock-outline font-size-24 text-primary"></i>
                        </span>
                      </div>
                      <h5 class="font-size-15 text-uppercase mt-4">What is the Downtime?</h5>
                      <p class="text-muted mb-0">
                        We strive to minimize downtime and notify our users of planned interruptions to allow them to adjust accordingly. However, if you encounter access issues during downtime, we appreciate your patience as we work swiftly to restore normal operations. Thank you for your understanding.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="mt-4 maintenance-box">
                    <div class="p-3">
                      <div class="avatar-sm mx-auto">
                        <span class="avatar-title bg-soft-primary rounded-circle">
                          <i class="mdi mdi-email-outline font-size-24 text-primary"></i>
                        </span>
                      </div>
                      <h5 class="font-size-15 text-uppercase mt-4">Do you need Support?</h5>
                      <p class="text-muted mb-0">
                        If you have any questions, encounter issues, or require assistance, our support team is here for you. Your satisfaction is our priority, and we're ready to help. Feel free to reach out for prompt assistance.
                        <a
                          href="mailto:support@ec-at.com"
                          class="text-decoration-underline"
                        >support@ec-at.com</a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end row -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>